import { NextSeo, NextSeoProps } from "next-seo";
import Head from "next/head";
import { useRouter } from "next/router";
import { SITE_NAME, SITE_URL, DEFAULT_IMAGE } from "@utils/constants";

export type Meta = {
  title?: string | null;
  description?: string | null;
  image?: string | null;
  path?: string;
  baseUrl?: string;
  keywords?: string;
} & NextSeoProps;

type Props = {
  meta: Meta;
  children: React.ReactNode;
};

export default function Page({ meta, children }: Props) {
  const { locale, locales = [], defaultLocale, asPath, pathname } = useRouter();
  const {
    title = "Dabdoob",
    description = locale?.includes("ar-") ? "اكتشف مجموعة واسعة من ألعاب الأطفال والألعاب التعليمية والهدايا في دبدوب. تسوق عبر الإنترنت لأفضل تشكيلة من الألعاب في السعودية." : "Discover a wide range of kids' toys, games, gifts, and educational products at Dabdoob. Shop online for the best selection of toys in Saudi Arabia.",
    image = DEFAULT_IMAGE,
    baseUrl = SITE_URL,
    path = asPath,
    keywords = locale?.includes("ar-") ? "ألعاب الأطفال، متجر ألعاب عبر الإنترنت، السعودية، دبدوب، ألعاب تعليمية، ألعاب الأطفال الرضع، ألعاب الأولاد، ألعاب البنات، ألعاب الأطفال الصغار، ألعاب الأطفال" : "kids toys, online toy store, Saudi Arabia, Dabdoob, educational toys, baby toys, boys toys, girls toys, toddler toys, kids games, Dabdoob Saudi Arabia",
  } = meta;

  const url = `${baseUrl}${path}`;
  const imageUrl = image || DEFAULT_IMAGE;

  return (
    <div>
      <NextSeo
        title={title}
        description={description}
        languageAlternates={locales.map((lang) => ({
          hrefLang: lang,
          href: lang === defaultLocale ? url : `${baseUrl}/${lang}${path}`,
        }))}
        canonical={locale === defaultLocale ? url : `${baseUrl}/${locale}${path}`}
        titleTemplate={pathname === "/" ? `%s` : `${SITE_NAME} | %s`}
        openGraph={{
          type: "website",
          url,
          title,
          description,
          site_name: SITE_NAME,
          locale,
          images: [
            {
              url: imageUrl.startsWith("https://") ? imageUrl : `${SITE_URL}${imageUrl}`,
              width: 600,
              height: 600,
              alt: title,
            },
          ],
        }}
      />
      <Head>
        <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="description" content={description} />
        <meta itemProp="name" content={title} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:title" content={title} />
        <meta itemProp="image" content={imageUrl} />
        <meta property="og:image" content={imageUrl} />
        <meta property="og:image:secure_url" content={imageUrl} />
        <meta name="twitter:image" content={imageUrl} />
        <meta name="keywords" content={keywords} />
        <meta property="og:image:width" content="600" />
        <meta property="og:image:height" content="600" />
        <meta name="twitter:domain" content="dabdoob.com" />
        <meta name="twitter:site" content="@dabdoob" />
        <meta name="robots" content="index, follow" />
        <meta itemProp="url" content={url} />
        <meta property="og:url" content={url} />
        <meta name="twitter:url" content={url} />
        <meta name="twitter:card" content="summary" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
        <meta name="HandheldFriendly" content="true" />
        <link rel="shortcut icon" href="/favicon.ico" type="image/x-icon" />
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        <link rel="manifest" href="/manifest.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#d41114" />
        <meta name="msapplication-TileColor" content="#008AF6" />
        <meta name="theme-color" content="#ffffff" />
        <meta name="google-site-verification" content="KHdrfR9uLhJFfIFgXe2V4pJegsOQLRr5fUeHVeo7Ew8" />
      </Head>
      {children}
    </div>
  );
}

