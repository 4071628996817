import type { ApiResponse, ErrorResponse, ReqKey } from "@types";
import type { Fetcher, SWRConfiguration } from "swr";

import useSWR from "swr/immutable";

export default function useRequestImmutable<
  R = unknown,
  D extends never = never,
  P extends never = never
>(
  meta: ReqKey<R, D, P>,
  config?:
    | SWRConfiguration<ApiResponse<R>, ErrorResponse<R>, Fetcher<ApiResponse<R>, ReqKey<R, D, P>>>
    | undefined
) {
  return useSWR(meta, config);
}
