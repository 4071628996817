import { useAppContext } from "@context/app";
import useEvents from "./useEvents";
import { CartItemModel, IBrandInput, ICategoryInput, ISkuModel } from "@types";
import { getNotifyConfig, updateCart } from "@utils/helpers";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useTranslations } from "next-intl";

function useVideoProductCard(
  sku: ISkuModel,
  detail: {
    product_name: string;
    product_id: number;
    product_slug: string;
  },
  category?: ICategoryInput,
  brand?: IBrandInput,
  subcategory?: ICategoryInput
) {
  const t = useTranslations();
  const { cart, setUserCart } = useAppContext();
  const [cartItems, setCartItems] = useState<CartItemModel[]>([]);
  const [item, setItem] = useState<undefined | ISkuModel>(undefined);
  const { skuAdded, skuRemoved } = useEvents();
  const { product_name, product_id, product_slug } = detail;
  const brand_id = sku.product?.brand.id ?? brand?.id;
  const brand_slug = sku.product?.brand.slug ?? brand?.slug;
  const category_id = sku.product?.category.id ?? category?.id;
  const category_slug = sku.product?.category.slug ?? category?.slug;
  const subcategory_id = sku.product?.subcategory?.id ?? subcategory?.id;
  const subcategory_slug = sku.product?.subcategory?.slug ?? subcategory?.slug;
  const source = {
    from_page: "video_feed",
    from_entity: "sku",
    from_entity_slug: sku.slug,
    from_page_section: "",
    from_page_section_entity: "",
    from_page_section_slug: "",
  };

  useEffect(() => {
    setCartItems(cart);
  }, [cart]);

  useEffect(() => {
    setItem(sku);
  }, [sku]);
  const currentSku = cartItems.find((s) => s.id === sku.id);

  const changeQty = (id: string | number, count: number) => {
    const data: CartItemModel[] = updateCart();
    for (const item of data) {
      if (item.id === id) {
        item.qty = count;
        item.gifts = [...item.gifts.slice(0, count)];
      }
    }
    setUserCart([...data]);
  };

  const deleteItem = (id: string | number) => {
    const data = [...cartItems];
    setUserCart(data.filter((item) => item.id !== id));
  };

  const handleAddItem = (sku: ISkuModel) => {
    if (currentSku && sku.buy_limit <= currentSku.qty) {
      toast.error(
        t("client_errors_onlyremaining").replace("@", `${sku?.buy_limit}`),
        getNotifyConfig()
      );
      return;
    }

    if (currentSku) {
      changeQty(currentSku.id, currentSku.qty + 1);
    } else {
      const currentCart = window.localStorage.getItem("DABDOOB_CART");
      const _currentCart = currentCart ? [...JSON.parse(currentCart)] : [];
      setUserCart([
        ..._currentCart,
        {
          differentOptions: false,
          image: sku.medias[0].url,
          qty: 1,
          productName: product_name,
          productSlug: product_slug,
          skuName: sku.name,
          price: sku.price.price,
          raw_price: sku.price.raw_price,
          gifts: [],
          id: sku.id,
          buy_limit: sku.buy_limit,
          productId: product_id,
          discount: sku.price.old_price ? sku.price.old_price - sku.price.price : 0,
          discount_percentage: sku.price.badge?.text ?? "",
          is_customizable: sku.product?.is_customizable,
          skuSlug: sku.slug,
          oldPrice: sku.price.old_price,
          brand_id,
          brand_slug,
          category_id,
          category_slug,
          subcategory_id,
          subcategory_slug,
        },
      ]);
    }
    skuAdded(source, sku, {
      id: product_id,
      slug: product_slug,
      category,
      brand,
      subcategory,
    });
  };
  const handleMinusItem = (sku: ISkuModel) => {
    if (currentSku) {
      if (currentSku.qty > 1) {
        changeQty(currentSku.id, currentSku.qty - 1);
        skuRemoved(source, sku, "Remove", {
          id: product_id,
          slug: product_slug,
          category,
          brand,
          subcategory,
        });
      } else {
        deleteItem(currentSku.id);
        skuRemoved(source, sku, "Delete", {
          id: product_id,
          slug: product_slug,
          category,
          brand,
          subcategory,
        });
      }
    }
  };

  return {
    cartItems,
    item,
    handleAddItem,
    handleMinusItem,
    changeQty,
    currentSku,
  };
}

export default useVideoProductCard;
