import useLatestCallback from "@hooks/useLatestCallback";
import { IVideoInput, PlayerSettings } from "@types";
import { DEFAULT_SETTINGS } from "@utils/player";
import React, {
  MouseEvent,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import cn from "classnames";
import { isServer } from "@utils/helpers";
import { usePlayerContext } from "@context/player";
import * as Bowser from "bowser";
import Image from "next/image";

type BrowserStatus = {
  isFirefox: boolean;
  isMobile: boolean;
};
export interface PlayerProps {
  sources: IVideoInput[]
  poster?: string;
  autoPlayOnSrcChange?: boolean;
  nextName?: string;
  showTheater?: boolean;
  id?: string | null;
  theaterMode?: boolean;
  onEnd?: () => void;
  onTimeUpdate?: (t: number, duration: number, seek: boolean) => void;
  onMetaDataLoaded?: () => void;
  onToggleTheater?: () => void;
  subtitle: string;
  startFrom?: number;
  onGoNext?: () => void;
  loop?: boolean
}

export type PlayerElement = {
  togglePlayHandler: () => void;
};

const Player = React.forwardRef<PlayerElement, PlayerProps>(
  (
    {
      sources,
      poster,
      nextName,
      autoPlayOnSrcChange = false,
      onTimeUpdate = () => { },
      onEnd,
      onMetaDataLoaded,
      onToggleTheater,
      subtitle,
      startFrom = 0,
      showTheater = false,
      theaterMode = false,
      id = null,
      onGoNext,
      loop = false
    },
    ref
  ) => {
    const videoRef = useRef<HTMLVideoElement | null>(null);
    const videoContainerRef = useRef<HTMLDivElement | null>(null);
    const animateHandle = useRef<NodeJS.Timeout>();
    const [currentVideoId, setCurrentVideoId] = usePlayerContext();

    const reloadMeta = useRef({
      from: 0,
      wasPlaying: false,
      changed: false,
      reload: false,
      startFrom: -1,
    });
    const seekMeta = useRef({
      wasPlaying: false,
      reload: false,
    });


    const [browser, setBrowser] = useState<BrowserStatus>(() => {
      if (isServer()) return { isFirefox: false, isMobile: false };

      const b = Bowser.getParser(window.navigator.userAgent);
      const isMobile = b.getPlatformType(true) !== "desktop";
      const isFirefox = b.satisfies({ firefox: ">0" }) ?? false;
      return {
        isFirefox,
        isMobile,
      };
    });

    const [settings, setSettings] = useState<PlayerSettings>({
      ...DEFAULT_SETTINGS,
      pipSupported: !browser.isFirefox,
      withSubtitle: subtitle !== "",
    });
    const [isLoading, setIsLoading] = useState(false);
    const [isPlaying, setIsPlaying] = useState<boolean>();
    const [animatePlay, setAnimatePlay] = useState(false);
    const [srcs, setSrcs] = useState<IVideoInput[]>([]);
    const { speedRate, volume, quality } = settings;

    const onTimeUpdateCallback = useLatestCallback(onTimeUpdate);


    useEffect(() => {
      const onChangeVisibility = () => {
        if (!videoRef.current?.paused) {
          videoRef.current?.pause()
        }
        else {
          videoRef.current?.play()
        }
      }
      window.addEventListener('visibilitychange', onChangeVisibility)
      return () => {
        window.removeEventListener('visibilitychange', onChangeVisibility)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    useEffect(() => {
      return () => {
        setCurrentVideoId((prev) => (prev === id ? null : prev));
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);
    useEffect(() => {
      if (id !== currentVideoId) {
        if (videoRef.current) {
          stopPlayer();
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, currentVideoId]);

    useEffect(() => {
      setSettings((prev) => ({ ...prev, withSubtitle: subtitle !== "" }));
    }, [subtitle]);

    const keyboardShortcuts = useLatestCallback((e: KeyboardEvent) => {
      if (!videoRef.current) return;
      if (currentVideoId !== id) return;
      if (
        document.activeElement?.tagName === "INPUT" ||
        document.activeElement?.tagName === "TEXTAREA" ||
        (document.activeElement?.tagName === "DIV" &&
          document.activeElement?.classList.contains("ql-editor"))
      )
        return;
      e.preventDefault();
      const video = videoRef.current;
      video.focus();
    });

    const animatePlayback = useCallback(() => {
      if (animateHandle.current) {
        clearTimeout(animateHandle.current);
        animateHandle.current = undefined;
      }
      setAnimatePlay(true);
      animateHandle.current = setTimeout(() => {
        setAnimatePlay(false);
      }, 500);
    }, []);
    const stopPlayer = useLatestCallback(async () => {
      if (videoRef.current) {
        const video = videoRef.current;
        video.pause();

        try {
          if (video === document.pictureInPictureElement) {
            setSettings((prev) => ({ ...prev, pipEnable: false }));
            await document.exitPictureInPicture();
            setSettings((prev) => ({ ...prev, pip: false }));
          }
        } catch (error) {
        } finally {
          setSettings((prev) => ({ ...prev, pipEnable: true }));
        }

        animatePlayback();
      }
    });
    const togglePlayHandler = useCallback(
      async (e?: MouseEvent<HTMLElement> | KeyboardEvent) => {
        if (videoRef.current) {
          const video = videoRef.current;
          if (video.paused || video.ended) {
            video.pause();
            try {
              if (video === document.pictureInPictureElement) {
                setSettings((prev) => ({ ...prev, pipEnable: false }));
                await document.exitPictureInPicture();
                setSettings((prev) => ({ ...prev, pip: false }));
              }
            } catch (error) {
            } finally {
              setSettings((prev) => ({ ...prev, pipEnable: true }));
            }
            video.play();
          } else {
            video.pause();
          }
          animatePlayback();
        }
        (e?.target as HTMLElement)?.blur();
      },
      [animatePlayback]
    );
    useImperativeHandle(
      ref,
      () => ({
        togglePlayHandler,
      }),
      [togglePlayHandler]
    );
    useEffect(() => {
      const onContentLoaded = () => {
        if (!("pictureInPictureEnabled" in document)) {
          setSettings((prev) => ({ ...prev, pipSupported: false }));
        }
      };
      const onKeyDown = (e: KeyboardEvent) => {
        if (
          document.activeElement?.tagName === "INPUT" ||
          document.activeElement?.tagName === "TEXTAREA" ||
          (document.activeElement?.tagName === "DIV" &&
            document.activeElement?.classList.contains("ql-editor"))
        ) {
          return;
        }

        if (
          e.key === " " /*&& e.target === document.body*/ ||
          e.key === "ArrowUp" ||
          e.key === "ArrowDown"
        ) {
          e.preventDefault();
        }
      };
      ///change fullscreen with esc
      const fullScreenChange = () => {
        if (
          !(
            document.fullscreenElement ||
            document.webkitFullscreenElement ||
            document.mozFullScreenElement ||
            document.msFullscreenElement
          )
        ) {
          timeUpdateHandler()
          setSettings((prev) =>
            prev.fullScreen ? { ...prev, fullScreen: false } : prev
          );
        }
      };
      document.onmouseup = function () {
        document.onmousemove = null;
      };

      document.addEventListener("keyup", keyboardShortcuts);
      document.addEventListener("keydown", onKeyDown);
      document.addEventListener("DOMContentLoaded", onContentLoaded);
      document.addEventListener("fullscreenchange", fullScreenChange);

      return () => {
        document.removeEventListener("keyup", keyboardShortcuts);
        document.removeEventListener("keydown", onKeyDown);
        document.removeEventListener("DOMContentLoaded", onContentLoaded);
        document.addEventListener("fullscreenchange", fullScreenChange);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [keyboardShortcuts]);

    useEffect(() => {
      if (videoRef.current) {
        videoRef.current.playbackRate = speedRate;
      }
    }, [speedRate]);

    useEffect(() => {
      if (!videoRef.current) return;
      videoRef.current.volume = volume;
    }, [volume]);

    useEffect(() => {
      if (videoRef.current) {
        const video = videoRef.current;
        reloadMeta.current.wasPlaying = false;
        const playing = !video.paused;
        reloadMeta.current.from = 0;
        reloadMeta.current.startFrom = startFrom;
        reloadMeta.current.wasPlaying = autoPlayOnSrcChange || playing;
        reloadMeta.current.changed = true;
        if (!(srcs.length) || sources[0].url !== srcs[0].url) {
          setSrcs([...sources]);
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sources]);
    useEffect(() => {
      timeUpdateHandler()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [theaterMode])
    useEffect(() => {
      const supportsOrientationChange = "onorientationchange" in window,
        orientationEvent = supportsOrientationChange
          ? "orientationchange"
          : "resize";
      const onResize = () => {
        const b = Bowser.getParser(window.navigator.userAgent);
        const isMobile = b.getPlatformType(true) !== "desktop";
        const isFirefox = b.satisfies({ firefox: ">0" }) ?? false;

        setBrowser({
          isFirefox,
          isMobile,
        });
      };
      const exitPip = async () => {
        if (document.pictureInPictureElement) {
          await document.exitPictureInPicture();
        }
      };
      onResize();
      exitPip();
      window.addEventListener(orientationEvent, onResize);

      return () => {
        window.removeEventListener(orientationEvent, onResize);
      };
    }, []);

    useEffect(() => {
      if (videoRef.current && isPlaying !== undefined) {
        const video = videoRef.current;
        const playing = !video.paused;
        if (playing) {
          video.pause();
        }
        reloadMeta.current.from = video.currentTime;
        reloadMeta.current.startFrom = -1;
        reloadMeta.current.wasPlaying = playing;
        reloadMeta.current.changed = true;
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [quality]);

    useEffect(() => {
      if (reloadMeta.current.changed) {
        if (videoRef.current) {
          const video = videoRef.current;
          reloadMeta.current.reload = true;
          video.load();
          video.focus();
          video.playbackRate = settings.speedRate;

          if (reloadMeta.current.wasPlaying && srcs.length) {
            video.play();
            setIsLoading(true)
          }
        }
        reloadMeta.current.changed = false;
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [srcs]);

    const replayAfterSeek = () => {
      if (seekMeta.current.reload && videoRef.current?.readyState === 4) {
        if (seekMeta.current.wasPlaying) {
          videoRef.current?.play();
        }
        seekMeta.current.reload = false;
      }
    };




    const canPlayHandler = () => {
      if (videoRef.current) {
        replayAfterSeek();
      }
    };

    const playHandler = () => {
      setIsPlaying(true);
    };
    const pauseHandler = () => {
      if (!seekMeta.current.reload) setIsPlaying(false);
    };
    const loadMetaDataHandler = () => {
      if (videoRef.current) {
        const video = videoRef.current;
        const from =
          reloadMeta.current.startFrom > -1
            ? (reloadMeta.current.startFrom * video.duration) / 100
            : reloadMeta.current.from;

        if (reloadMeta.current.reload && from < video.duration) {
          video.currentTime = from;
          video.playbackRate = speedRate;
        }
        reloadMeta.current.reload = false;
        replayAfterSeek();
        onMetaDataLoaded?.();
      }
    };

    const timeUpdateHandler = () => {
      if (videoRef.current) {
        const video = videoRef.current;

        onTimeUpdateCallback(video.currentTime, video.duration, false);
        if (!seekMeta.current.reload) setIsLoading(false);
      }
    };



    const endedHandler = () => {
      if (videoRef.current) {
        onEnd?.();
      }
    };

    return (
      <div
        ref={videoContainerRef}
        className={cn(
          "nplay-video-container !w-full !h-full outline-none  focus:outline-none border-none !border-0 bg-black rounded-2xl overflow-hidden",
          animatePlay ? "cursor-default" : ""
        )}
      >
        <video
          loop={loop}
          ref={videoRef}
          className={cn(
            "relative nplay-video w-full h-full top-0 outline-none align-middle block object-contain focus:outline-none rounded-2xl pointer-events-none",
            settings.fullScreen
              ? " top-1/2 -translate-y-1/2 sm:translate-y-0 sm:top-0 sm:w-full sm:h-full subtitle"
              : "",
            browser.isFirefox ? "firefox" : "noFireFox"
          )}
          preload="metadata"
          poster={poster}
          muted={settings.isMuted}
          autoPlay={autoPlayOnSrcChange}
          // eslint-disable-next-line react/no-unknown-property
          controls={false}
          onPlay={playHandler}
          onPause={pauseHandler}
          onLoadedMetadata={loadMetaDataHandler}
          onSeeked={replayAfterSeek}
          onEnded={endedHandler}
          onCanPlay={canPlayHandler}
          onWaiting={() => setIsLoading(true)}
          onPlaying={() => {
            setIsLoading(false);
          }}
        >
          {srcs.map((m, idx) => (
            <source key={m.url} src={m.url} />
          ))}
        </video>
        {(typeof isPlaying === "undefined" || isLoading || !isPlaying) && poster ? (
          <div className="absolute inset-0 pointer-events-none">
            <div className="w-full h-full overflow-hidden relative">
              <Image src={poster} alt="Poster" layout="fill" placeholder="blur" blurDataURL={poster} />
            </div>
          </div>
        ) : null}
      </div>
    );
  }
);

Player.displayName = "Player";

export default Player;
