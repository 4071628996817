import { useAppContext } from "@context/app"
import { convertNumber, convertStringNumber } from "@utils/digits"
import Image from "next/image"
import { useRouter } from "next/router"
import { useEffect } from "react"
import Button from "./button"
import { BsDash, BsPlus, BsTrash } from "react-icons/bs"
import { IBrandInput, ICategoryInput, ISkuModel } from "@types"
import { updateCart } from "@utils/helpers"
import Link from "next/link"
import { useTranslations } from "next-intl"
import useVideoProductCard from "@hooks/useVideoProductCard"

interface CardProps {
  sku: ISkuModel;
  hasLink?: boolean;
  productSlug?: string;
  productName?: string;
  productId?: number;
  category?: ICategoryInput;
  brand?: IBrandInput;
  subcategory?: ICategoryInput
}

const VideoProductCard: React.FC<CardProps> = ({ sku, hasLink, productSlug, productName, productId, category, brand, subcategory }) => {
  const t = useTranslations()
  const { locale } = useRouter()
  const { decimal, currency, setUserCart } = useAppContext()

  useEffect(() => {
    setUserCart(updateCart());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const product_name = sku.product?.name ?? productName ?? ""
  const product_id = productId || -1
  const product_slug = sku.product?.slug ?? productSlug ?? ""

  const { item, handleAddItem, handleMinusItem, currentSku } = useVideoProductCard(sku, { product_id, product_name, product_slug }, category, brand, subcategory)

  if (!item) return <></>
  return (<div className="w-full flex" key={item.id}>
    <div className="relative w-16 h-16 rounded-xl overflow-hidden">
      <Image
        src={item.medias[0].url}
        layout="fill"
        quality={100}
        alt={item.name}
        objectFit="cover"
      ></Image>
    </div>
    <div className="flex-1 px-2">
      {hasLink ?
        <Link href={`/product/${product_slug}?default_sku=${item.id}&source=video_feed&source_entity=sku&source_slug=${sku.slug}`} passHref prefetch={false}>
          <a>
            <p className=" text-start font-bold text-xs line-clamp-2 leading-5  text-[#333]">{convertStringNumber(locale, product_name)}</p>
            <p className=" text-start font-normal text-xs line-clamp-2 leading-5  mb-1 text-[#999]">
              {convertStringNumber(locale, item.name)}
            </p>
          </a>
        </Link>
        : <>
          <p className=" text-start font-bold text-xs line-clamp-2 leading-5  text-[#333]">{convertStringNumber(locale, product_name)}</p>
          <p className=" text-start font-normal text-xs line-clamp-2 leading-5  mb-1 text-[#999]">
            {convertStringNumber(locale, item.name)}
          </p>
        </>}

      {item.buy_limit ? <p className="w-full font-extrabold text-lg text-[#333] !text-start !justify-start left-to-right">
        <span
          dir="rtl"
          className=" font-normal text-[10px] text-left px-0.5 "
        >
          {currency}
        </span>
        {item.price.old_price && <s className=" font-normal text-xs px-1 ">
          {convertNumber(locale, item.price.old_price.toFixed(decimal))
          }
        </s>}
        {convertNumber(locale, (item.price.price).toFixed(decimal))}
      </p> : <p className=" text-xs text-[#999] font-bold text-start">{t("client_general_outofstock")}</p>}
    </div>
    {item.buy_limit ? <div className="">
      {!currentSku ? <Button
        onClick={() => handleAddItem(item)}
        className=" h-8 shadow-md font-extrabold text-sm !w-20 rounded-full"
        size="slim"
        type="submit"
        theme="blue"
      >
        {t("client_buttons_add")}
      </Button> : <div
        className="md:w-1/5 sm:w-full w-full"
        style={{ direction: "ltr" }}
      >
        <div className=" flex flex-wrap  w-[max-content] m-auto items-center justify-center">
          <button
            className={`w-6 h-6 rounded-full border-[#008AF6] ${currentSku.qty > 1 ? "border-2" : ""
              }  text-[#008AF6] text-xl hover:text-primary hover:border-primary flex items-center justify-center`}
          >
            {currentSku.qty > 1 ? (
              <BsDash
                size={24}
                onClick={() =>
                  handleMinusItem(item)
                }
              />
            ) : (
              <BsTrash
                className=" cursor-pointer"
                size={24}
                onClick={() =>
                  handleMinusItem(item)
                }
              />
            )}
          </button>
          <p className="h-12 w-10 text-[#333] text-center flex justify-center items-center font-extrabold text-xl mx-2">
            {convertNumber(locale, currentSku.qty)}
          </p>
          <button
            className={`w-6 h-6 rounded-full  flex items-center justify-center border-2   ${item.buy_limit <= currentSku.qty ? "text-gray-400 border-gray-400 cursor-not-allowed" : "border-[#008AF6]  text-[#008AF6] text-xl hover:text-primary hover:border-primary"}`}
            onClick={() => handleAddItem(item)}
          >
            <BsPlus size={24} />
          </button>
        </div>
      </div>}
    </div> : null}
  </div>)
}

export default VideoProductCard