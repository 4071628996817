import { PlayerSettings } from "@types";

export function formatTime(timeInSeconds: number) {
  if (timeInSeconds) {
    const result = new Date(timeInSeconds * 1000).toISOString().substring(11, 19).split(":");

    return {
      minutes: result[1] ?? "00",
      seconds: result[2] ?? "00",
      hours: result[0] ?? "00",
    };
  }
  return {
    minutes: "00",
    seconds: "00",
    hours: "00",
  };
}
export const DEFAULT_SETTINGS: PlayerSettings = {
  fullScreen: false,
  pip: false,
  quality: "720p",
  volume: 1,
  speedRate: 1,
  pipEnable: true,
  fullScreenEnable: true,
  pipSupported: true,
  lastVolume: 1,
  isMuted: false,
  withSubtitle: true,
  subtitleOn: true,
};
