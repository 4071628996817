import { useAppContext } from "@context/app";
import useEvents from "@hooks/useEvents";
import BagIcon from "@svg/BagIcon";
import PathIcon from "@svg/PathIcon";
import { ISkuModel } from "@types";
import { toArabicNumber } from "@utils/digits";
import Link from "next/link";
import { useRouter } from "next/router";
import { BsX } from "react-icons/bs";

interface VideoActionCardProps {
  onClose: () => void;
  onNext?: () => void;
  onBack?: () => void;
  isList: boolean;
  index: number;
  length?: number;
  hasMore?: boolean;
  sku: ISkuModel
}

const VideoActionCard: React.FC<VideoActionCardProps> = ({ onClose, onBack, onNext, isList, index, length, hasMore, sku }) => {
  const { locale } = useRouter();
  const { cartViewed } = useEvents()
  const { cart } = useAppContext()
  const ItemsQty = cart.reduce((previousValue, currentValue) => {
    return previousValue + currentValue.qty;
  }, 0);
  const source = {
    from_page: "video_feed",
    from_entity: "sku",
    from_entity_slug: sku.slug || "",
    from_page_section: "",
    from_page_section_entity: "",
    from_page_section_slug: "",
  }
  return (
    <div className="w-full flex flex-wrap md:bg-[linear-gradient(0deg,rgba(0,0,0,0)0%,rgba(0,0,0,0)100%)]  bg-[linear-gradient(0deg,rgba(0,0,0,0)0%,rgba(0,0,0,0.8)100%)] py-2 rounded-2xl overflow-hidden md:px-0 px-2">
      <div className="flex-1 flex">
        {isList && <div className="w-24 h-12 md:bg-white bg-transparent md:border border-0 border-[#eee] rounded-xl  flex">
          <button className=" flex-1 p-3 h-full flex items-center justify-center disabled:cursor-not-allowed !outline-none md:stroke-[#333] stroke-white" disabled={index === 0} onClick={onBack}>
            <PathIcon className=" rotate-180 " stroke="inherit" />
          </button>
          <div className="w-[1px] h-full bg-[#eee] md:inline-block hidden"></div>
          <button className=" flex-1 p-3 h-full flex items-center justify-center disabled:cursor-not-allowed !outline-none md:stroke-[#333] stroke-white" disabled={hasMore ? false : index + 1 === length} onClick={onNext}>
            <PathIcon stroke="inherit" />
          </button>
        </div>}
      </div>
      <div className="flex space-x-3">
        <Link href="/cart" passHref prefetch={false}>
          <a onClick={() => { onClose(); cartViewed(source) }} className=" border-0 outline-0">
            <div className="w-12 h-12 md:bg-white bg-transparent md:border border-0 border-[#eee] rounded-xl flex justify-center items-center relative md:stroke-[#333] stroke-white">
              <BagIcon stroke="inherit" />
              {ItemsQty ? (
                <div className="w-5 h-5 rounded-full bg-brandBlue absolute flex justify-center items-center top-6 right-0 ">
                  <span className=" text-white font-normal text-xs ">
                    {locale?.includes("ar") ? toArabicNumber(ItemsQty) : ItemsQty}
                  </span>
                </div>
              ) : null}
            </div>
          </a>
        </Link>
        <div className="w-12 h-12 md:bg-white bg-transparent md:border border-0 border-[#eee] rounded-xl  flex justify-center items-center cursor-pointer md:text-[#333] text-white" onClick={onClose}>
          <BsX size={22} />
        </div>
      </div>
    </div>
  );
}

export default VideoActionCard;